$color-font-primary: #111111;
$color-font-secondary: #333333;
$color-font-tertiary: #777777;

$color-success: #098309;
$color-info: #356daf;
$color-warning: #ffb800;
$color-danger: #d86565;

$color-success-fill: #ddeedd;
$color-info-fill: #dbe5f0;
$color-warning-fill: #feedcd;
$color-danger-fill: #f7d4d4;

$color-bkg-primary: #ffffff;
$color-bkg-hover: #f6f6f6;
$color-bkg-secondary: #fcfcfc;
$color-bkg-selected: #e9f5fb;
$color-bkg-disabled: #f3f3f3;
$color-bkg-overlay: rgba(0, 0, 0, 0.25);

$color-border: #cacaca;
$color-border-primary: #cdcdcd;
$color-border-bold: #000000;
$color-border-selected: #02a4ff;
$color-border-disabled: #e3e3e3;
$color-border-neutral: rgba(0, 0, 0, 0.05);

$brand-primary-default: #1e4702;
$brand-primary-pressed: #103800;
$brand-primary-disabled: #a7b796;

$color-green: #1bc47d;
$color-green-dark: #04a66c;

$color-category-grey: #d3d3d3;
$color-category-dark-grey: #808080;
$color-category-salmon: #ee90a4;
$color-category-coral: #ef9383;
$color-category-orange: #e79a55;
$color-category-umber: #cda655;
$color-category-khaki: #b6ae55;
$color-category-olive: #9db554;
$color-category-green: #74bd54;
$color-category-aquamarine: #56bf7f;
$color-category-teal: #58bd9d;
$color-category-turquoise: #5abbb0;
$color-category-powder: #5bb9c0;
$color-category-blue: #5db7d2;
$color-category-cornflower: #68b2eb;
$color-category-lavender: #9aa8eb;
$color-category-violet: #bb9deb;
$color-category-pink: #dc8feb;
