@use "@/styles/colors.scss";

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  // Keep Radix dialogs from shifting the content when the scrollbar is removed
  padding-right: var(--removed-body-scroll-bar-size, 0) !important;
}
