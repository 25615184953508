@use "@/styles/colors.scss";
@use "@/styles/mixins";
@use "@/components/atoms/Textarea/textarea.mixin.scss";

:root {
  --max-width: 1100px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-inter);
}

a {
  color: inherit;
  text-decoration: none;
}

textarea {
  @include textarea.textarea-defaults;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

// Utility class to hide the content visually, yet provide the content to screen readers
.visually-hidden {
  @include mixins.visually-hidden;
}
