@use "@/styles/colors.scss";

// Apply our defaults for a textarea
@mixin textarea-defaults {
  padding: 0.5rem 1rem;
  border: 0.0625rem solid colors.$color-border-primary;
  border-radius: 0.25rem;
  background-color: colors.$color-bkg-primary;
  color: colors.$color-font-secondary;
  font-size: 1rem;

  resize: vertical;

  &::placeholder {
    color: colors.$color-font-tertiary;
  }

  &:disabled,
  &:read-only {
    background-color: colors.$color-bkg-disabled;
    color: colors.$color-font-tertiary;
    cursor: not-allowed;
  }
}
